import React, { PropsWithChildren, createContext, useCallback, useContext, useEffect, useState } from 'react'
import { BasicField, FormField } from '../interfaces'
import { FormBuilderUtils } from '../utils'

interface FormAccessibilityContextType {
  tabIndexMap: { [key: string]: number }
  getTabIndex: (key: string) => number
}

const FormAccessibilityContext = createContext<FormAccessibilityContextType | undefined>(undefined)

export const FormAccessibilityProvider: React.FC<PropsWithChildren<{ fields: FormField[] }>> = ({
  children,
  fields,
}) => {
  const [tabIndexMap, setTabIndexMap] = useState<{ [key: string]: number }>({})

  const getTabIndex = useCallback(
    (key: string) => {
      if (key in tabIndexMap) return tabIndexMap[key]

      const prefix = key.substring(0, 5)
      const parentKey = Object.keys(tabIndexMap).find(k => k.startsWith(prefix))

      return parentKey ? tabIndexMap[parentKey] : 0
    },
    [tabIndexMap],
  )

  useEffect(() => {
    const sortedFields = FormBuilderUtils.assignTabIndexes(fields)
    const updatedTabIndexMap = {}

    sortedFields.forEach(f => {
      updatedTabIndexMap[f.key] = (f as BasicField).tabIndex
    })
    setTabIndexMap(updatedTabIndexMap)
  }, [fields])

  return (
    <FormAccessibilityContext.Provider value={{ tabIndexMap, getTabIndex }}>
      {children}
    </FormAccessibilityContext.Provider>
  )
}

export const useFormAccessibility = () => {
  const context = useContext(FormAccessibilityContext)
  if (context === undefined) {
    throw new Error('useFormAccessibility must be used within a FormAccessibilityProvider')
  }
  return context
}
