import { Box, Card, Icon, Text, VStack } from '@chakra-ui/react'
import { CopyButton } from '@unmand-systems/components'
import React from 'react'
import { LuSave } from 'react-icons/lu'

export const SavedProgressPage: React.FC<{ guid: string }> = ({ guid }) => {
  const getSavedProgressUrl = () => {
    const url = new URL(window.location.origin + window.location.pathname)
    url.searchParams.set('saved-progress', guid)
    return url.toString()
  }

  const SavedMessage = (
    <Box className="hide-on-print">
      <Card variant="outline" p="10" bg="green.50">
        <VStack textAlign="center">
          <Icon as={LuSave} boxSize={15} color="green.500" />
          <Text fontSize="xl" fontWeight="semibold" color="green.500">
            Progress saved
          </Text>
          <Text fontSize="sm" color="gray.500">
            Use this link to return to your submission at any time
          </Text>
          <CopyButton
            mt="2"
            size="sm"
            content={getSavedProgressUrl()}
            variant="ordinaryOutline"
            label="Link"
            showLabel
          />
        </VStack>
      </Card>
    </Box>
  )

  return (
    <VStack
      flex="1"
      bg="gray.100"
      alignItems="center"
      justifyContent="center"
      gap={6}
      h="100%"
      className="hide-on-print"
    >
      {SavedMessage}
    </VStack>
  )
}

export default SavedProgressPage
