import { Box, Button, CloseButton, Text, useToast } from '@chakra-ui/react'
import { AddressFieldKeys, BasicField, FormField, FormFieldTypes, READABLE_ADDRESS_FIELDS } from '../interfaces'

const showErrorToast = (
  allFormFields: FormField[],
  e: { message?: string; key: string },
  toast: ReturnType<typeof useToast>,
  scrollFieldIntoView: (fieldId: string) => void,
) => {
  const field = allFormFields?.find((f: FormField) => f.guid === e.key || e.key.startsWith(f.guid!))

  if (!field) {
    return
  }

  let fieldName = (field as BasicField).name || 'Field'

  if (field.type === FormFieldTypes.Address) {
    const addressKey = Object.values(AddressFieldKeys).find(key => e.key.endsWith(key))

    if (addressKey) {
      const readableFieldName = READABLE_ADDRESS_FIELDS[addressKey as AddressFieldKeys]

      if (readableFieldName) {
        fieldName = `${readableFieldName}`
      }
    }
  }

  toast({
    position: 'top',
    isClosable: true,
    duration: 10000,
    status: 'error',
    variant: 'subtle',
    render: ({ onClose }) => (
      <Box
        color="white"
        bg="red.500"
        p={3}
        fontSize="sm"
        display="flex"
        alignItems="center"
        rounded="base"
        boxShadow="base"
      >
        <Box flex="1">
          <Text as="span" fontWeight="bold">
            {fieldName}
            {' - '}
          </Text>
          <Text as="span">{e?.message ?? 'Invalid value'}</Text>
        </Box>
        <Button variant="outline" colorScheme="white" size="xs" ml={4} onClick={() => scrollFieldIntoView(e.key)}>
          See Error
        </Button>
        <CloseButton onClick={onClose} color="white" ml="2" />
      </Box>
    ),
  })
}

export const ErrorUtils = {
  showErrorToast,
}
