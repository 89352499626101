import { Box, Button, Center, HStack } from '@chakra-ui/react'
import { MouseEvent, useState } from 'react'
import { EMPTY_FN } from '../constants'
import { useFormState } from '../context/FormStateContext'
import { ErrorResponse, FlatData, FormResponse } from '../interfaces'
import { FormsApi } from '../services'
import { FormRendererUtils, GeneralUtils, StyleUtils } from '../utils'

interface FormNavigationAndSubmitProps {
  formId?: string
  formResponse: FormResponse | null
  onPageChange: (
    change: 'increment' | 'decrement',
    pageGuid?: string,
    e?: MouseEvent<HTMLElement>,
    isFromStepper?: boolean,
  ) => Promise<void>
  setFormProgressSaveGuid: (guid: string) => void
  isPreview: boolean
  auth: { authToken?: string; password?: string }
  manualSubmitBtnRef: React.RefObject<HTMLButtonElement>
  isDisabled: boolean
  setErrorMsg: (msg: string) => void
  onDisplayErrors: () => void
}

const FormNavigationAndSubmit = (props: FormNavigationAndSubmitProps) => {
  const {
    formId,
    isPreview,
    formResponse,
    onPageChange,
    setFormProgressSaveGuid,
    auth: { authToken, password },
    manualSubmitBtnRef,
    isDisabled,
    setErrorMsg,
    onDisplayErrors,
  } = props

  const [isSavingProgress, setIsSavingProgress] = useState<boolean>(false)

  const {
    fieldPages,
    page,
    pages,
    isReadOnly,
    hiddenPageIds,
    formMethods: {
      getValues,
      formState: { isSubmitting },
    },
  } = useFormState()

  const { style } = formResponse ?? {}
  const { colors, buttons } = StyleUtils.getTheme(style ?? {})

  const pageKeys = GeneralUtils.sortPages(pages ?? []).map(page => page.guid)
  const currPageGuid = pageKeys.find(key => key === page)
  const isLastPage = currPageGuid === pageKeys.filter(key => !hiddenPageIds.includes(key)).at(-1) || !currPageGuid
  const { nextButtonText, previousButtonText, nextButtonAlignment, previousButtonAlignment } =
    pages.find(p => p.guid === page) || {}

  const onSaveFormProgress = async (formData: FlatData) => {
    if (isPreview) {
      return
    }

    setIsSavingProgress(true)

    try {
      if (authToken) {
        const response = await FormsApi.saveFormProgressPrivate({
          formId: formId!,
          formData,
          authToken,
          password,
        })
        setFormProgressSaveGuid(response.guid)
      } else {
        const response = await FormsApi.saveFormProgressPublic({
          formToken: formId!,
          formData,
          password,
        })
        setFormProgressSaveGuid(response.guid)
      }
    } catch (e) {
      const error = e as ErrorResponse
      console.error(error)
      setErrorMsg(error.message!)
    }

    setIsSavingProgress(false)
  }

  return (
    <HStack my="4" justifyContent="center" alignItems="start" gap={0}>
      {page !== pageKeys[0] && (
        <Center
          py={2}
          px={4}
          w="100%"
          justifyContent={previousButtonAlignment ?? undefined}
          sx={
            previousButtonAlignment === 'stretch'
              ? {
                  '& > *': {
                    w: '100%',
                  },
                }
              : {}
          }
        >
          <Button
            px={8}
            type="button"
            isDisabled={page === '1'}
            onClick={e => onPageChange('decrement', undefined, e)}
            size={buttons.size}
            borderRadius={StyleUtils.toPixels(buttons.borderRadius)}
            {...StyleUtils.getSubtleButtonStyle(colors.primaryColor)}
          >
            {previousButtonText ? previousButtonText : 'Back'}
          </Button>
        </Center>
      )}

      {!(isLastPage && isReadOnly) && (
        <Center
          py={2}
          px={4}
          w="100%"
          justifyContent={nextButtonAlignment ?? undefined}
          sx={
            nextButtonAlignment === 'stretch'
              ? {
                  '& > *': {
                    w: '100%',
                  },
                }
              : {}
          }
        >
          <Box>
            <Button
              px={8}
              type={isLastPage ? 'submit' : 'button'}
              onClick={async e => {
                if (!isLastPage) {
                  e.preventDefault()
                  await onPageChange('increment')
                }

                setTimeout(() => {
                  onDisplayErrors()
                }, 100)
              }}
              isLoading={isSubmitting}
              isDisabled={isDisabled && isLastPage}
              size={buttons.size}
              border={buttons.border ? `${StyleUtils.toPixels(buttons.borderWidth)} solid` : undefined}
              borderColor={buttons.border ? buttons.borderColor : undefined}
              borderRadius={StyleUtils.toPixels(buttons.borderRadius)}
              {...StyleUtils.getSolidButtonStyle(colors.primaryColor, colors.buttonTextColor)}
            >
              {nextButtonText ? nextButtonText : isLastPage ? 'Submit' : 'Continue'}
            </Button>
            {formResponse?.form.allowSaveFormProgress && (
              <Button
                type="button"
                onClick={async () => {
                  const updatedData = FormRendererUtils.updateDataByJsonpaths(getValues(), fieldPages, {}, EMPTY_FN)
                  if (!isPreview) {
                    await onSaveFormProgress(updatedData)
                  }
                }}
                mt="4"
                display="flex"
                variant="link"
                isLoading={isSavingProgress}
                isDisabled={isDisabled}
                size="xs"
              >
                Save and Continue Later
              </Button>
            )}
          </Box>
        </Center>
      )}

      <Button id="manualSubmitBtn" display="none" type="submit" ref={manualSubmitBtnRef} />
    </HStack>
  )
}

export default FormNavigationAndSubmit
