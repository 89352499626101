import { ChakraProvider, LightMode } from '@chakra-ui/react'
import { theme } from '@unmand-systems/components'
import 'react-datepicker/dist/react-datepicker.css'
import '../node_modules/react-grid-layout/css/styles.css'
import { Form } from './components'
import { ErrorBoundary } from './components/core/ErrorBoundary'
import { FormConditionsProvider } from './context/FormConditionsContext'
import { FormStateProvider } from './context/FormStateContext'
import './datepicker.css'
import { UnmandFormComponent } from './interfaces/unmand-form-component'
import './main.css'

interface AppProps {
  unmandForm: UnmandFormComponent
  scriptReference: HTMLOrSVGScriptElement | null
}

export const App: React.FC<AppProps> = ({ unmandForm, scriptReference }) => (
  <ChakraProvider theme={theme}>
    <LightMode>
      <ErrorBoundary>
        <FormStateProvider>
          <FormConditionsProvider>
            <Form unmandForm={unmandForm} scriptReference={scriptReference} />
          </FormConditionsProvider>
        </FormStateProvider>
      </ErrorBoundary>
    </LightMode>
  </ChakraProvider>
)
