import { Button, Flex, Icon, IconButton, Tab, TabList, Tabs, Text, Tooltip } from '@chakra-ui/react'
import { useEffect } from 'react'
import { FiPlus, FiTrash2 } from 'react-icons/fi'
import { HiExclamationCircle } from 'react-icons/hi'
import tinycolor from 'tinycolor2'
import { useFormState } from '../context/FormStateContext'
import { useConfirmationDialog } from '../hooks'
import { Page, Style } from '../interfaces'
import { GroupedPageUtils, StyleUtils } from '../utils'

interface GroupedPageTabsProps {
  activePage: Page
  selectedTabIndex: number
  setSelectedTabIndex: (idx: number) => void
  style?: Style
}

export const GroupedPageTabs = ({ activePage, selectedTabIndex, setSelectedTabIndex, style }: GroupedPageTabsProps) => {
  const {
    formFields,
    onRemoveEntryFromGroupedPage,
    onAddEntryToGroupedPage,
    formMethods: {
      getValues,
      formState: { errors },
    },
  } = useFormState()

  const { showConfirmDialog: openConfirmationDialog, RenderDialog: ConfirmationDialog } = useConfirmationDialog({
    title: 'Are you sure?',
    message: 'Are you sure you want to remove this? This cannot be undone.',
    primaryBtnLabel: 'Remove',
    secondaryBtnLabel: 'Cancel',
  })

  const activeGroupedPageIndexes = Array.from(
    { length: GroupedPageUtils.getListIndexCount(formFields) },
    (_, idx) => idx,
  )

  const tabIndexesWithError = activePage?.isGrouped
    ? Array.from(new Set(formFields.filter(f => !!errors[f.key]).map(f => f.listIndex)).values())
    : []

  useEffect(() => {
    if (activePage?.guid) {
      setSelectedTabIndex(0)
    }
  }, [activePage?.guid])

  const { colors, layout } = StyleUtils.getTheme(style ?? {})

  return (
    <>
      <Flex
        w="100%"
        px="4"
        pos="sticky"
        zIndex="1"
        top={-6}
        bg={layout.backgroundColor}
        alignItems="center"
        gap={4}
        overflow="hidden"
        maxW="100%"
        borderBottom="1px"
        borderColor={tinycolor(layout.backgroundColor).isLight() ? 'blackAlpha.300' : 'whiteAlpha.500'}
        justifyContent="space-between"
      >
        <Tabs overflow="auto" index={selectedTabIndex} onChange={idx => setSelectedTabIndex(idx)}>
          <TabList borderColor="transparent">
            {activeGroupedPageIndexes?.map((_, idx) => {
              return (
                <Tab
                  minH="50px"
                  position="relative"
                  key={`sidebar-btn-${idx}`}
                  whiteSpace="nowrap"
                  opacity={0.8}
                  borderColor="transparent"
                  _selected={{
                    opacity: 1,
                    borderColor: colors.primaryColor,
                    color: colors.primaryColor,
                  }}
                >
                  <Flex justifyContent="space-between" w="100%" alignItems="center">
                    {tabIndexesWithError.includes(idx) && <Icon mr={2} as={HiExclamationCircle} color="red.600" />}
                    <Text>{GroupedPageUtils.getTabLabel(activePage, idx, formFields, getValues)}</Text>
                    {activeGroupedPageIndexes.length > 1 && selectedTabIndex === idx && selectedTabIndex !== 0 && (
                      <Tooltip label="Remove entry">
                        <IconButton
                          colorScheme="red"
                          size="xs"
                          variant="ghost"
                          aria-label="Remove entry"
                          p={1}
                          ml={2}
                          mr={-2}
                          icon={<FiTrash2 />}
                          onClick={() => {
                            openConfirmationDialog(() => {
                              onRemoveEntryFromGroupedPage(activePage, idx)
                              setSelectedTabIndex(idx !== 0 ? idx - 1 : 0)
                            })
                          }}
                        />
                      </Tooltip>
                    )}
                  </Flex>
                </Tab>
              )
            })}
          </TabList>
        </Tabs>
        <Button
          size="xs"
          flex="none"
          {...StyleUtils.getSubtleButtonStyle(colors.primaryColor)}
          leftIcon={<FiPlus />}
          onClick={() => {
            onAddEntryToGroupedPage(activePage, null)
            setSelectedTabIndex(activeGroupedPageIndexes.length)
          }}
        >
          Add
        </Button>
      </Flex>
      <ConfirmationDialog />
    </>
  )
}
